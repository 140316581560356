.navigation {
  .text-stroke {
    -webkit-text-stroke: 2px #fff;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    transition: background-size 1s cubic-bezier(0.19, 1, 0.22, 1);
    background-repeat: no-repeat;
    background-image: linear-gradient(
      135deg,
      #fff 0%,
      #fff 50%,
      transparent 50.1%
    );
    background-size: 0 100%;
    cursor: pointer;
    &:hover {
      background-size: 220% 220%;
      transition: background-size 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

.bar {
  position: absolute;
  background-color: #fff;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  &.left {
    left: 0;
    top: 0;
    width: 2px;
    height: 0%;
  }
  &.right {
    right: 0;
    top: 0;
    width: 2px;
    height: 0%;
  }
  &.top {
    left: 0;
    top: 0;
    width: 0;
    height: 2px;
  }
  &.bottom {
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
  }
}

.text-stroke:hover {
  & .left,
  .right {
    height: 100%;
  }
  .top,
  .bottom {
    width: 100%;
  }
}
