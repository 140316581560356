.marquee {
  position: static;
  overflow-x: hidden;
  user-select: none;
  width: 100vw;
  --offset: 20vw;
  --move-initial: calc(-50% + var(--offset));
  --move-final: calc(-100% + var(--offset));
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  animation-play-state: running;
  backface-visibility: hidden;
  will-change: transform;
  text-transform: uppercase;
}

.marquee span {
  font-size: 5vw;
  font-weight: 900;
  padding: 0 2vw;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
